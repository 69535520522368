export const showFirmArr = [
    '/dataDisplay/Wisdomscreen',
    '/dataDisplay/floorplan',
    '/dataDisplay/DataView3d',
    
    '/intelligentsystem/home',
    '/intelligentsystem/alarmData',
    '/intelligentsystem/dataShow',
    '/intelligentsystem/pathShow',
    '/intelligentsystem/ring',
    '/intelligentsystem/Smart',
    '/intelligentsystem/plan',
    '/intelligentsystem/visual',
    '/intelligentsystem/myinfo',

    '/msgAlert',
]