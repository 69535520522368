import Vue from "vue";
import Vuex from "vuex";
import {
    infos
} from "@/api/login.js"
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [
		createPersistedState({
			key: 'stort',
			paths: ['userInfo',"SETPerList",'showFirm','showData','permission']
		})
	],
	state: {
		showModel:false,
		nav:[],//导航
		userInfo: {
			username: '',
			password: '',
			token: ''
		},
		showData: {//获取数据展示中的 iframe链接所需的参数
			T_data: '',
			T_plan: '',
			T_v3d: ''
		},
		showFirm:{
			plan:'',
			T_name:'',
			bl: false
		},
		permission:[],//按钮权限列表
		searchs:false,//是否显示搜索弹窗
        previousRoute:null,//上一个路由
	},
    
	getters:{//计算属性
		previousRoute:state=>state.previousRoute,
		permission:state=>state.permission,
		showData:state=>state.showData,
		navData:state=>state.nav,
		showFirm:state=>state.showFirm,
		showModel:state=>state.showModel

	},
	mutations: {
        
        SETROUTE(state,data){//按钮权限列表
			state.previousRoute = data
		},
		SETMODEL(state,data){//按钮权限列表
			state.showModel = data
		},
		SETPER(state,data){//按钮权限列表
			state.permission = data
		},
		SETDATA(state,data){//获取数据展示中的 iframe链接所需的参数
			state.showData = data
		},
		SETPANY(state,data){///////////////////
			state.showFirm = data
		},
		SETNAV(state,data){///////////////////
			state.nav = data
		},
		setuserInfo(state, data) {
			state.userInfo = data
		},
	},
	actions:{//管理mutations
         // ... 其他 actions ...
        generateRoutes({ commit }) {
            return new Promise((resolve, reject) => {
                infos({}).then(response => {
                    const {data:result} = response 

                    commit('SETPER', result.Data.Permission) //缓存权限到vuex

                    const routes = formatRoutes(result.Data.Data);
                    commit('SETNAV', routes);

                    resolve(routes);
                }).catch(error => {
                    console.error('获取路由数据失败:', error);
                    reject(error);
                });
            });
        },
        SETROUTE({commit},data){
			commit('SETROUTE',data)
		},
		SETMODEL({commit},data){
			commit('SETMODEL',data)
		},
		SETPER({commit},data){
			commit('SETPER',data)
		},
		SETNAV({commit},data){
			//console.log('重新设置菜单1',data)
			commit('SETNAV',data)
		},
		SETPANY({commit},data){
			commit('SETPANY',data)
		},
		SETDATA({commit},data){//获取数据展示中的 iframe链接所需的参数
			commit('SETDATA',data)
		},
	},
	modules: {},
});

/**
 * 
 * @param {} data 路由列表
 * @param {*} parentPath 拼接的组件地址
 * @param {*} level 用于判断子选项层级
 * @returns 
 */
function formatRoutes(data, parentPath = '', level = 0) {
    return data.map(item => {
        const currentPath = parentPath ? `${parentPath}/${item.T_permission}` : `/${item.T_permission}`;
        const children = item.Children && item.Children.length > 0 
            ? formatRoutes(item.Children, currentPath, level + 1) 
            : [];
        const route = {
            path: currentPath,
            name: item.T_permission,
            icon: item.T_icon,
            component: level == 0 
                ? () => import(`@/views/${item.T_permission}/index.vue`)
                : () => import(`@/views/${parentPath.slice(1)}/${item.T_permission}.vue`),
                urls: level == 0 
                ? `@/views/${item.T_permission}/index.vue`
                :`@/views/${parentPath.slice(1)}/${item.T_permission}.vue`,
            meta: { title: item.T_name},
            children: children,
            // ...(level === 0 && children.length > 0 && { redirect: children[0].path }), // 仅在level为0时添加redirect
        };
        return route;
    });
}