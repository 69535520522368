import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/views/layout/layout"
import intelligentrouterData from "./modules/intelligentrouter.js"
import storts from "../store/index"
import {
    infos
} from "../api/login.js"
Vue.use(VueRouter);

const routes = [
    {
        path: "",
        name: "login",
        hidden: true,
        meta: {
            title: '你好，请登录',
        },
        component: () => import('@/views/login.vue')
    }, {
        path: "/index",
        name: "index",
        hidden: true,
        meta: {
            title: '宝智达冷链系统v3.0',
        },
        component: () => import('@/views/layout/index.vue')
    },
    {
        path: '*',
        name: "error",
        hidden:true,
        component: () => import('@/components/error.vue'),
    },
    {
        path: "/layout",
        name: "layout",
        hidden: false,
        redirect: '/home/workbench',
        meta: {
            title: '工作台',
        },
        component: layout,
        children: [{
            path: '/home',
            name: 'home',
            redirect: '/home/workbench',
            icon: "icon-shouye",
            component: () => import('@/views/home/index.vue'),
            meta: {
                title: '首页',
            },
            children: [{
                path: '/home/workbench',
                name: 'workbench',
                icon: "icon-shouye",
                component: () => import('@/views/home/workbench.vue'),
                meta: {
                    title: '工作台',
                },
            }]
        }]
    },
    {
        path: '/msgAlert',
        name: "msgAlert",
        hidden:true,
        meta: {
            title: '短信通知',
        },
        component: () => import('@/views/msgAlert.vue'),
    },
    ...intelligentrouterData.intelligentrouterData
];

const router = new VueRouter({
    mode: "hash", //hash
    base: process.env.BASE_URL,
    routes,
});
//登录拦截//未登录返回到登录界面
router.beforeEach(async (to, from, next) => {
    storts.dispatch('SETROUTE', from);
    
    if(storts.state.nav.length==0){
        if(to.path == '/'){
            next()
        }else{
            if(to.path.includes('/msgAlert')){//处理短信链接跳转
                document.title = to.meta.title
                next()	
                return
            }
            //获取数据
            let res = await getNavData();
            //处理路由配置数据
            let dynamicRouter = await addDynamic(res)
            dynamicRouter.map(item=>{
                routes[3].children.push(item)
                router.addRoute('layout', item);
            })
            //动态添加路由
            // router.addRoutes(dynamicRouter)
            // router.addRoute('layout', dynamicRouter);
            
            //vuex数据缓存
            storts.dispatch('SETNAV', dynamicRouter) //缓存到vuex
            //判断是否有标题，赋值标题
            to.meta.title ? document.title = to.meta.title : document.title = '宝智达-冷链系统3.0'
            next({path:to.path})//不写刷新会白屏
        }
    }else{
        if(to.path == '/'){
            storts.dispatch('SETPANY', {T_name:'',bl:false,plan:''}) //显示公司  登录页不显示
            storts.dispatch('SETNAV', []) //缓存到vuex
        }
        next()//不写刷新会白屏
    }
})
//获取数据
function getNavData(){
    return new Promise(resolve=>{
        infos({}).then(res => {
            const {Permission:resIt} = res.data.Data
            storts.dispatch('SETPER', resIt) //缓存权限到vuex
            resolve(res.data.Data.Data)
        })
    })
}


function addDynamic(result, parentPath = '', level = 0) {
    routes[3].children = []
    return result.map(item=>{
        const currentPath = parentPath ? `${parentPath}/${item.T_permission}` : `/${item.T_permission}`;
        const children = item.Children && item.Children.length > 0 
            ? addDynamic(item.Children, currentPath, level + 1) 
            : [];
        const route = {
            path:currentPath,
            name: item.T_permission || '',
            icon: item.T_icon,
            id:item.Id,
            component: level == 0 
            ? () => import(`@/views/${item.T_permission}/index.vue`)
            : () => import(`@/views/${parentPath.slice(1)}/${item.T_permission}.vue`),
            meta: {
                title: item.T_name,
            },
            children:children,
        }
        return route
    })
    // result.forEach(item=>{
    //     routes[3].children.push({
    //         path: '/'+item.T_permission,
    //         name: item.T_permission || '',
    //         icon: item.T_icon,
    //         component: () => import(`@/views/${item.T_permission}/index.vue`),
    //         meta: {
    //             title: item.T_name,
    //         },
    //         children:addDynamicEr(item),
    //         // redirect:addDynamicEr(item)[0].path
    //     })
    // })
    // return routes
}

// function addDynamic(result, parentPath = '', level = 0) {
//     routes[3].children = []
//     result.forEach(item=>{
//         routes[3].children.push({
//             path: '/'+item.T_permission,
//             name: item.T_permission || '',
//             icon: item.T_icon,
//             component: () => import(`@/views/${item.T_permission}/index.vue`),
//             meta: {
//                 title: item.T_name,
//             },
//             children:addDynamicEr(item),
//             // redirect:addDynamicEr(item)[0].path
//         })
//     })
//     return routes
// }

export default router;
