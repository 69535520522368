let baseURL = ''
//是否在控制台显示请求日志，本地环境启用，打包环境禁用
let showHttpLog = false 

// baseURL = 'http://coldtest.coldbaozhida.com:9999/api/'//测试通用地址
baseURL = 'https://cold.coldbaozhida.com/api/'//生产通用地址

// baseURLGood = 'http://coldtest.coldbaozhida.com:9999/Cold_GoodsOrder'//测试订单
baseURLGood = 'https://cold.coldbaozhida.com/Cold_GoodsOrder'//生产订单
// 
// baseURLWork = 'http://coldtest.coldbaozhida.com:9999/Cold_WorkOrder'//测试工单系统
baseURLWork = 'https://cold.coldbaozhida.com/Cold_WorkOrder'//生产工单系统

baseURLPush = 'https://cold.coldbaozhida.com/ColdDataPush'//生产金卫信
// baseURLPush = 'http://coldtest.coldbaozhida.com:9999/ColdDataPush'//测试金卫信
 
baseURLColdMonitor = "https://cold.coldbaozhida.com/"//环动


// baseURLyunaiot = "https://yunaiot.baozhida.cn/API/"//物联智控
baseURLyunaiot = "https://cold.coldbaozhida.com/Cold_Monitor_AOIT/"//物联智控

module.exports = {
    baseURLPush:baseURLPush,
	baseURL:baseURL,//通用地址
	baseURLColdMonitor:baseURLColdMonitor,//环动
	baseURLyunaiot:baseURLyunaiot,//物联智控
	baseURLGood:baseURLGood,//订单专用
	baseURLWork:baseURLWork,//工单系统
	showHttpLog:showHttpLog
}