import axios from 'axios'
import { 
	baseURL,//通用url
	baseURLGood,//订单专用
	baseURLWork,//工单系统
	baseURLColdMonitor,//环动
    baseURLPush,//金卫信
	baseURLyunaiot,//物联智控
} from './base.js'
import elementui from 'element-ui';
import qs from 'qs'
import store from '../store/index.js'
import router from '../router/index.js'
import {
	showLoading,
	hideLoading
} from './loading.js';


// 创建 axios 实例
const service = axios.create({
	baseURL: baseURL,
	withCredentials: false,// 是否跨站点访问控制请求
	timeout: 60000,
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',//x-www-form-urlencoded
		// "User_tokey":store.state.userInfo.token
	},
})

// 请求拦截器
service.interceptors.request.use((config) => {
	config.url.split('/').join('').includes('Order')?config.baseURL = baseURLGood://订单专用
	config.url.split('/').join('').includes('Addr')?config.baseURL = baseURLGood://订单地址专用
	config.url.split('/').join('').includes('Couriers')?config.baseURL = baseURLGood://订单配送员专用

	config.url.split('/').join('').includes('JWX')?config.baseURL = baseURLPush://金卫信



	config.url.split('/').join('').includes('WorkOrder')?config.baseURL = baseURLWork://工单专用

	config.url.split('/').join('').includes('Cold_Monitor')?config.baseURL = baseURLColdMonitor://环动
	['Product','Device','DeviceDevicePush'].includes(config.url.split('/').join(''))?config.baseURL = baseURLyunaiot:
	config.baseURL = baseURL//通用地址

	if(!['Product','Device'].includes(config.url.split('/')[1])){
        config.data.User_tokey = store.state.userInfo.token
    }

	if(config.url.split('/').join('')=='Cold_MonitorDeviceDelete')config.data.User_tokey = store.state.userInfo.token

    
	if(config.method != 'get'){
        config.data = qs.stringify(config.data)
    }
    
	// config.headers['T_pid'] = store.state.T_pid // 让每个请求携带自定义token  //设置请求头
	return config
}, (error) => {
	// 错误抛到业务代码
	error.data = {}
	// return Promise.resolve(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {
	// hideLoading(1)
	showStatus(response.data)
	return response
}, (error) => {
	// 错误抛到业务代码
	// router.push({
	// 	path:'/error'
	// })
	// elementui.Message.error('系统异常，联系管理员处理')
	// router.replace({
	// 	name: '/',
	// })
	error.data = {}
	return Promise.resolve('error')
})

const showStatus = (status) => {
	setTimeout(function(){
		hideLoading()
	},1000)
	switch (status.Code) {
		case 200:
			break
		case 201:
			elementui.Message.error('登录过期，请重新登陆')
			store.commit('setuserInfo', {
				username: store.state.userInfo.bzd_username,
				password: store.state.userInfo.bzd_password,
				token: ''
			})
			setTimeout(function() {
				router.replace({
					name: 'login',
				})
			}, 1000)
			break
		case 202:
			elementui.Message.error(status.Msg + '[' + status.Code + ']')
			break	
		case 203:
			//防止动环报203sn错误
			break
		case 500:
			elementui.Message.error('服务器错误[' + status.Code + ']')
			break
		case 501:
			elementui.Message.error('服务未实现[' + status.Code + ']')
			break
		case 502:
			elementui.Message.error('网络错误[' + status.Code + ']')
			break
		default:
			elementui.Message.error(status.Msg + '[' + status.Code + ']')
	}
}


export {
	service as axios
}
