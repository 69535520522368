<template>
    <div class="suspension-panel" v-if="$route.fullPath!='/'">
        <!-- 新消息 -->
        <div title="新消息" class="suspension-panel-item suspension-panel-msg" @click="systemMeg">
            <div class="suspension-panel-msg-icons">
                <i class="el-icon-message-solid"></i>
            </div>
            <span style="writing-mode: vertical-rl;letter-spacing: 2px;font-size: 14px;margin-top: 8px;">新消息</span>
        </div>
        <!-- 公司管理 -->
        <div class="suspension-panel-item suspension-panel-firm" title="公司管理">
            <div class="suspension-panel-firm-icon" @click="isFirm = !isFirm">
                <i class="el-icon-office-building" style="font-size:20px;color: #515767;"></i>
            </div>
            <transition name="el-zoom-in-center">
                <div class="suspension-panel-firm-gradient"
                :style="{background:routerArray.includes($route.fullPath) ? bg_white : bg_gradation}"
                v-show="isFirm && outerVisible==false">
                    <img src="@/assets/img/3df25da.png" >
                    <div class="suspension-panel-firm-gradient-mains">
                        <div style="display: flex;justify-content: flex-end;">
                            <i class="el-icon-close" @click="isFirm = !isFirm" style="font-size:20px;color: #c2c8d1;"></i>
                        </div>
                        <h2>{{showFirm.T_name?showFirm.T_name:'还未选择公司哟~'}}</h2>
                        <p>若还有其他公司，还能查看其他公司哦~</p>
                        <el-button type="primary" @click="_choiceFirm" style="margin-top: 16px;width: 100%;">
                            查看公司
                        </el-button>
                    </div>
                </div>
            </transition>
            
                
        </div>
        <!-- 全屏 -->
        <div @click="quan" title="全屏" class="suspension-panel-item suspension-panel-screen">
            <i class="el-icon-full-screen" style="font-size:20px;color: #515767;"></i>
        </div>
        <!-- 系统通知 -->
        <el-dialog title="系统通知" :append-to-body="true" width="800px" custom-class="custom-dialog"
         :visible.sync="outerVisible">
            <el-tabs v-model="activeName"  @tab-click="handleClick('show')">
                    <el-tab-pane :label="items.label" :name="items.name" v-for="items,j in tabNav" :key="j">
                            <div 
                            v-loading="loading"
                            element-loading-text="拼命加载中"
                            element-loading-spinner="el-icon-loading"
                            element-loading-background="rgba(234, 244,255, 1)"
                            class="systemMsg" v-if="messageArray.length!=0">
                                <div class="systemMsg_mani" v-for="item,index in messageArray" :key="index" @click="onclickinner(item)">
                                        <div class="systemMsg_mani_item systemMsg_mani_items">
                                            <div :class="item.IsShow==0?'newData':'readData'">
                                                <!--{{item.IsShow==0?'未读':'已读'}}-->
                                            </div>
                                            <div class="txts">
                                                【{{item.title}}】{{ item.content }}
                                            </div>
                                        </div>
                                        <div class="systemMsg_mani_item">{{ item.CreateTime }}</div>
                                </div>
                            </div>
                            <div class="systemMsg" v-else>
                                <el-empty description="暂无系统消息"></el-empty>
                            </div>
                    </el-tab-pane>
                </el-tabs>
                <div style="margin-top: 30px;">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background small
                    :current-page="params.page" :page-size="params.page_z" layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                    </el-pagination>
                </div>
            <el-dialog :title="innerData.title" :append-to-body="true" custom-class="custom-dialog" width="40%" :visible.sync="innerVisible" append-to-body>
                <ul class="innersystemMsg" style="padding: 0 20px;line-height: 40px;">
                    <li>{{innerData.content}}</li>
                </ul>
            </el-dialog>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { noticeGetList,noticeIsReadBulletin } from "@/api/notices.js";
export default {
    computed: mapGetters(["showFirm"]),
    watch:{
    	"$route"(val,oldVal){
            // this.isFirm = val.path=='/index'?false:true
    	}	
    },
    data() {
        return {
            isFirm:false,
            // 定义渐变颜色的变量
            bg_gradation: 'linear-gradient(180deg, rgba(217, 231, 251, 1),  rgba(248,251,255, 1))',
            bg_white: '#fff',
            routerArray:['/index','/'],

            outerVisible:false,
            innerVisible:false,
            activeName: '0',
            tabNav:[
                {label:'未读消息',name:'0'},
                {label:'已读消息',name:'1'},
                {label:'全部消息',name:'2'},
            ],
            loading:false,
            messageArray:[],
            innerData:{},
            total:0,
            params:{
                page:1,
                page_z:10
            }
        }
    },
    watch:{
        '$route'(val,oldVal){
           if (val.path!='/' && oldVal.path=='/')  {
            this.initializeFun()
           }
        }
    },
    methods: {
        //选择公司
        _choiceFirm(){
            this.$router.push({
                path: '/companyManagement/Company'
            })
        },
        // 全屏
        quan() {
            if (window.innerHeight === window.screen.height) {// 利用屏幕分辨率和window对象的内高度来判断兼容IE
                document.exitFullscreen()
            } else {
                document.documentElement.requestFullscreen()
            }
        },
        async initializeFun(){
            const {data:result} = await this.notApi()
            if (result.Code==200) {
                let res = result.Data.Data || []
                // console.log('新消息',res)

                if(res.length>0){
                    this.outerVisible = true
                    // if(this.$store.state.previousRoute.name == 'login')this.outerVisible = true
                }
                this.messageArray = res 
                this.total = result.Data.Num || 0
            }else{
                this.messageArray =  []
                this.total = 0
            }
            this.loading = false
        },
        systemMeg(){
            this.outerVisible = true
            this.activeName = '0'
            this.handleClick('show')
            this.loading = true
        },
        async onclickinner(data){
            if(data.IsShow!=1){
                let {id} = data
                await noticeIsReadBulletin({is_show:1,id})
            }
            this.innerVisible = true
            this.innerData = data
        },
    	handleCurrentChange(pages) {
            this.params.page = pages
            this.handleClick('page')
        },
        handleSizeChange(pages) {
            this.params.page_z = pages
            this.handleClick('page_z')
        },	
        async handleClick(innt){
            this.loading = true
            if(innt=='show')this.params.page = 1
            const result = await this.notApi()
            if (result.data.Code==200) {
                this.messageArray = result.data.Data.Data || []
                this.total = result.data.Data.Num || 0
            }else{
                this.messageArray =  []
                this.total = 0
            }
            this.loading = false
        },
        notApi(){
            return new Promise((resolve) => {
                const result = noticeGetList({...this.params,is_show:this.activeName!='2'?this.activeName:''})
                resolve(result)
            })
        },
    }
}
</script>


<style lang="scss">
/* 自定义对话框样式 */
.custom-dialog {
  background-color: rgba(#eaf4ff,1); /* 背景色 */
//   border-color: #fff; /* 边框色 */
//   color: #fff;
}
 
/* 如果需要修改标题栏和内容区域的样式，可以使用下面的选择器 */
.custom-dialog .el-dialog__header {
  /* 修改标题栏样式 */
  color: #fff;
}
.custom-dialog  .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    background-color: #979da3;
    color: #fff;
    min-width: 30px;
    border-radius: 2px;
}
.custom-dialog  .el-input__inner {
    background-color: transparent;
}
</style>

<style lang="scss">
@mixin f-direction {
    display: flex;
    align-items: center;
    justify-content: center;
}
.suspension-panel{
    user-select: none;
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 1000;
    &-item{
        width:2.5rem;
        color: #909090;
        border-radius: 50%;
        box-shadow: 0 15px 35px -2px rgba(0, 0, 0, .05), 0 5px 15px 0 rgba(0, 0, 0, .05);
        background-color:#fff;
        border: 1px solid rgba(#f1f1f1,.5);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        @include f-direction;
    }
    &-msg{
        overflow: hidden;
        height: 6rem;
        flex-direction: column;
        border-radius: 2.6667rem;
        &-icons{
            font-size:20px;
            background: linear-gradient(180deg, #2060ff 50%, #ac5bfd); /* 定义渐变背景 */
            -webkit-background-clip: text; /* 将背景裁剪到文本区域 */
            -webkit-text-fill-color: transparent; /* 将文本填充颜色设为透明 */
            background-clip: text;
            text-fill-color: transparent;
            animation: ling 1.5s forwards infinite;
            animation-delay: 0.1s;
            transform-origin: top center; /* 设置旋转的基点在铃铛的顶部中央 */ 
        }
    }
    &-firm{
        margin: 1rem 0 0;
        height:2.5rem;
        line-height: 1;
        background-color:#fff;
        position: relative;
        &-icon{
            width: 100%;
            height: 100%;
            @include f-direction;
            border-radius: 50%;
        }
        &-gradient{
            position: absolute;
            right: 60px;
            padding:20px;
            min-width:250px;
            box-shadow: 0 15px 35px -2px rgba(0, 0, 0, .05), 0 5px 15px 0 rgba(0, 0, 0, .05);
            border: 1px solid rgba(#f1f1f1,.5);
            box-shadow: 0 2px 10px 0 rgba(30, 128, 255, .1);
            border-radius: 8px;
            img{
                position: absolute;
                top: -48px; 
                left: 0;
                width: 82px;
                height: auto;
            }
            &-mains{
                h2{
                    margin: 0;
                    color: #252933;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 26px;
                }
                p{
                    color: #515767;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                }
            }
        }
    }
    &-screen{
        margin: 1rem 0 0;
        height:2.5rem;
        line-height: 1;
        
        
    }
}


.systemMsg{
        height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &_mani{
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba(#bec1c4,.5);
            padding: 12px 20px;
            overflow-x: hidden;
            &_items{
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap; /* 禁止换行 */  
                overflow: hidden;    /* 隐藏超出部分 */  
                text-overflow: ellipsis; /* 使用省略号表示溢出 */
                .newData{
                    width: 8px;
                    height: 8px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background: red;
                }
                .readData{
                    width: 8px;
                    height: 8px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    background: #67c23a;
                }
                .txts{
                    white-space: nowrap; /* 禁止换行 */  
                    overflow: hidden;    /* 隐藏超出部分 */  
                    text-overflow: ellipsis; /* 使用省略号表示溢出 */
                    margin-left: 10px;
                }
            }
            &_item:nth-child(2){
                flex-shrink: 0;
                margin-left: 60px;
            }
        }
        &_mani:hover{
            background: rgba(#bec1c4,.3);
            cursor: pointer;
        }
    }
    .innersystemMsg{
        max-height: 500px;
        overflow-y: auto;
    }
@keyframes ling {
    0%{
        transform: rotate(0);
    }20%{
        transform: rotate(35deg);
    }40%{
        transform: rotate(-20deg);
    }60%{
        transform: rotate(10deg);
    }75%{
        transform: rotate(-5deg);
    }80%{
        transform: rotate(0deg);
    }100%{
        transform: rotate(0deg);
    }
}
</style>