<template>
	<div id="app">
		<!-- <showFirm></showFirm> -->
        <suspension-panel></suspension-panel>
        <router-view v-show="screenWidth>599 || $route.path=='/msgAlert'""></router-view>
		<div class="qr" v-show="screenWidth<599 &&  $route.path!='/msgAlert'"">
			<div class="qr-mai">
				<img src="./assets/img/Qr.png" alt="请访问冷链小程序"
					style="width: 150px;height: 150px;margin-bottom: 20px;">
				<div style="color: #ccc;font-size: 14px;">暂不支持小窗口设备</div>
			</div>
		</div>
	</div>
</template>
<script>
	import AOS from "aos";
	import showFirm from "@/components/showFirm.vue"
    import suspensionPanel from "@/components/suspension-panel.vue";

	export default {
		data() {
			return {
				paw:'',
				screenWidth: document.body.clientWidth,
			}
		},
		components:{
			showFirm,suspensionPanel
		},
		
		mounted() {
			// //console.log(navigator.userAgent.indexOf('Chrome') > -1) //Opera浏览器 Firefox浏览器 Chrome浏览器 Safari浏览器 compatible（ie）是当前浏览器true
			const that = this;
			AOS.init();
			window.onresize = () => (() => {
				that.screenWidth = document.body.clientWidth
			})();
		},
	}
</script>
<style lang="scss">
	// @import url('./assets/css/scrolls.css');
	@import url('./assets/css/placeholder-style.css');
	@import url('./assets/css/font.css');
	//#da8037 #57968f #854f8f #825d6e #7c6c4f #2f4f3d
	.qr {
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		.qr-mai {
			width: 300px;
			height: 300px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			background: #fff;
			border-radius: 5px;
		}
	}

	* {
		margin: 0;
		padding: 0;
	}

	body,
	html {
		// background: #313642;//2f3044
		// background: url('./assets/img/BJT.png');
		background: #3a394e;//2f3044
		height: 100%;
		overflow-y: auto;
	}
	
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #fff;
	}
	// .el-dialog__wrapper{
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	.el-dialog {
	// 	    position: relative;
	// 	   margin: 0 !important;
	// 	    background: #FFF;
	// 	    border-radius: 2px;
	// 	    box-shadow: 0 1px 3pxrgba(0,0,0,.3);
	// 	    box-sizing: border-box;
	// 	    width: 50%;
	// 	}
	// }
	a {
		color: #fff;
		text-decoration: none;
	}

	img {
		vertical-align: middle;
	}

	#nav {
		padding: 30px;

		a {
			font-weight: bold;
			color: #fff;

			&.router-link-exact-active {
				color: #42b983;
			}
		}
	}
</style>
