// 登录接口
import {
	axios
} from '../utils/http.js'

const loginApi = {
	login: '/v3/Login_verification',
	// infos: '/v3/User/Info',
	infos: '/v3/Menu/List',
}




//登陆
export function login(data) {
	return axios({
		url: loginApi.login,
		method: 'post',
		data:data
	})
}

//获取用户权限
export function infos(data) {
	return axios({
		url: loginApi.infos,
		method: 'post',
		data:data
	})
}